import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import MainLayout from './layouts/main';
import AuthLayout from './layouts/auth/AuthLayout';
import DashboardAppPage from './pages/DashboardAppPage';
import ListPage from './pages/ListPage';
import CalendarPage from './pages/CalendarPage';
import GalleryPage from './pages/GalleryPage';
import KanbanPage from './pages/KanbanPage';
import ChartPage from './pages/ChartPage';
import GanttPage from './pages/GanttPage';
import MapPage from './pages/MapPage';
import TimelinePage from './pages/TimelinePage';
import TourPage from './pages/Tour';
import SignIn from './pages/auth/SignIn';
import SignUp from './pages/auth/SignUp';
import ForgotPassword from './pages/auth/ForgotPassword';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    palette_style?: any;
  }
}

export default function Router() {
  const routes = useRoutes([
    {
      path: '',
      children: [
        { element: <Navigate to="/dashboard" />, index: true }
      ],
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> }
      ],
    },
    {
      path: '/List',
      element: <MainLayout />,
      children: [
        { element: <Navigate to="/List/app" />, index: true },
        { path: 'app', element: <ListPage /> }
      ],
    },
    {
      path: '/Calendar',
      element: <MainLayout />,
      children: [
        { element: <Navigate to="/Calendar/app" />, index: true },
        { path: 'app', element: <CalendarPage /> }
      ],
    },
    {
      path: '/Gallery',
      element: <MainLayout />,
      children: [
        { element: <Navigate to="/Gallery/app" />, index: true },
        { path: 'app', element: <GalleryPage /> }
      ],
    },
    {
      path: '/Kanban',
      element: <MainLayout />,
      children: [
        { element: <Navigate to="/Kanban/app" />, index: true },
        { path: 'app', element: <KanbanPage /> }
      ],
    },
    {
      path: '/Gantt',
      element: <MainLayout />,
      children: [
        { element: <Navigate to="/Gantt/app" />, index: true },
        { path: 'app', element: <GanttPage /> }        
      ],
    },
    {
      path: '/Map',
      element: <MainLayout />,
      children: [
        { element: <Navigate to="/Map/app" />, index: true },
        { path: 'app', element: <MapPage /> }
      ],
    },
    {
      path: '/Timeline',
      element: <MainLayout />,
      children: [
        { element: <Navigate to="/Timeline/app" />, index: true },
        { path: 'app', element: <TimelinePage /> }
      ],
    },
    {
      path: '/Chart',
      element: <MainLayout />,
      children: [
        { element: <Navigate to="/Chart/app" />, index: true },
        { path: 'app', element: <ChartPage /> }
      ],
    },
    {
      path: '/Tour',
      element: <MainLayout />,
      children: [
        { element: <Navigate to="/Tour/app" />, index: true },
        { path: 'app', element: <TourPage /> }
      ],
    },
    {
      path: '/Signin',
      element: <AuthLayout />,
      children: [
        { element: <Navigate to="/SignIn/auth" />, index: true },
        { path: 'auth', element: <SignIn /> }
      ],
    },
    {
      path: '/Signup',
      element: <AuthLayout />,
      children: [
        { element: <Navigate to="/SignUp/auth" />, index: true },
        { path: 'auth', element: <SignUp /> }
      ],
    },
    {
      path: '/Forgotpassword',
      element: <AuthLayout />,
      children: [
        { element: <Navigate to="/ForgotPassword/auth" />, index: true },
        { path: 'auth', element: <ForgotPassword /> }
      ],
    }
  ]);

  return routes;
}
