import {
  Container,
  Typography,
  Link,
  Grid,
  TextField,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useResponsive from '../../hooks/useResponsive';
import SocialLogin from '../../sections/@auth/SocialLoginButtons';
import LoginIcon from "@mui/icons-material/Login";

const SignIn = () => {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'md');

  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Grid
        container
        rowSpacing={4}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          py: 4,
          px: {xs: 1, md: 4},
          borderRadius: "4px",
          boxShadow: "0 0 64px 0 rgba(0,0,0,0.1)",
          backgroundColor: 'white',
          marginTop: 0,
          maxHeight: '93vh',
          overflow: 'auto'
        }}
      >
        <Grid item xs={12} sx={{ paddingTop: '0 !important' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
            <Link href="/">
              <Box
                component="img"
                sx={{
                  width: 60,
                  height: 45,
                  objectFit: 'contain',
                  marginTop: '2px'
                }}
                alt="Logo"
                src="/assets/logo_auth.png"
              />
            </Link>
          </Box>
          <Typography variant="h3" textAlign="center">
            Sign in
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            placeholder="Email"
            type="email"
            required
          ></TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            placeholder="Password"
            type="password"
            required
          ></TextField>
        </Grid>

        <Grid item xs={6}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked sx={{ color: '#FFD232', '&.Mui-checked': { color: '#FFD232', }, }} />}
              label="Remember me"
            />
          </FormGroup>
        </Grid>

        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Link href="/ForgotPassword/auth" variant="body1" sx={{ color: '#0D0934' }}>
            Forgot password?
          </Link>
        </Grid>

        <Grid item xs={12}>
          <Button
            href="#"
            size="large"
            variant="contained"
            endIcon={<LoginIcon />}
            sx={{
              width: "100%",
              backgroundColor: '#FFD232',
              color: '#0D0934'
            }}
          >
            Sign in
          </Button>
        </Grid>

        <SocialLogin />

        <Grid
          item
          xs={12}
          columnSpacing={1}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Typography
            variant="body1"
            sx={{
              display: "inline",
            }}
          >
            Don&apos;t have an account?
          </Typography>
          <Link
            href="/SignUp/auth"
            variant="body1"
            sx={{
              paddingLeft: "4px",
              color: '#0D0934'
            }}
          >
            Sign Up
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SignIn;
