import { useState } from "react";
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { setColumns, setRows } from '../../redux/store';
import useResponsive from '../../hooks/useResponsive';
import ViewFooter from '../../components/view-footer/ViewFooter';
import GoogleMapReact from 'google-map-react';
import LocationPin from "./LocationPin";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const CENTER = {
  lat: 49.84120,
  lng: 24.02975
};

type Props = {
  columns: any;
  rows: any;
  setColumns: (columns: any) => void;
  setRows: (columns: any) => void;
  open: boolean;
};

const meetings = [
  {
    title: 'Meeting Meeting Meeting',
    color: '#FFB800',
    level: 3,
    from: '04/02/2023 08:00:00',
    to: '04/07/2023 08:00:00'
  },
  {
    title: 'Meeting',
    color: '#FFB800',
    level: 3,
    from: '04/09/2023 08:00:00',
    to: '04/15/2023 08:00:00'
  },
  {
    title: 'Meeting',
    color: '#C92929',
    level: 4,
    from: '04/11/2023 08:00:00',
    to: '04/19/2023 08:00:00'
  },
  {
    title: 'Meeting',
    color: '#159639',
    level: 5,
    from: '04/06/2023 08:00:00',
    to: '04/22/2023 08:00:00'
  },
  {
    title: 'Meeting',
    color: '#FFB800',
    level: 6,
    from: '04/04/2023 08:00:00',
    to: '04/12/2023 08:00:00'
  },
  {
    title: 'Meeting',
    color: '#C92929',
    level: 7,
    from: '04/19/2023 08:00:00',
    to: '04/26/2023 08:00:00'
  }
];

const MapView = (props: Props) => {
  const { columns, rows, setRows, open } = props;
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'md');
  const [visibleAddRowPanel, setVisibleAddRowPanel] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [center, setCenter] = useState(CENTER);

  const setLocation = (location: any) => {
    setCenter(location);
  };

  return (
    <Box sx={{ p: {xs: 0.5, md: 1} }}>
      <Box sx={{ height: {xs: `${window.innerHeight - (open ? 310 : 264)}px`, md: `${window.innerHeight - 217}px`} }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY, libraries: 'places' }}
          center={center}
          defaultZoom={12}
        >
          {rows.map((row: any) => (
            row.location && <LocationPin
              key={`map-${row.id}`}
              lat={row.location.lat}
              lng={row.location.lng}
              row={row}
              setLocation={setLocation}
            />
          ))}
        </GoogleMapReact>
      </Box>

      <ViewFooter visibleAddRowPanel={visibleAddRowPanel} rowData={rowData} setVisibleAddRowPanel={setVisibleAddRowPanel} setRowData={setRowData} />
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  columns: state.columns,
  rows: state.rows
});

const mapDispatchToProps = {
  setColumns,
  setRows
};

export default connect(mapStateToProps, mapDispatchToProps)(MapView);
