import SvgColor from '../../../components/svg-color';

const icon = (name:string) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'My List',
    path: '/List',
    icon: icon('ic_user'),
  },
  {
    title: 'Tour',
    path: '/Tour',
    icon: icon('ic_user'),
  },
  {
    title: 'Sign In',
    path: '/SignIn',
    icon: icon('ic_user'),
  },
  {
    title: 'Sign Up',
    path: '/SignUp',
    icon: icon('ic_user'),
  },
  {
    title: 'Forgot Password',
    path: '/ForgotPassword',
    icon: icon('ic_user'),
  }
];

export default navConfig;
