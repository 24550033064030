import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

const Logo = React.forwardRef<any, LogoProps>(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();
  
  const logo = (
    <Box
      ref={ref}
      component="img"
      sx={{
        width: 35,
        height: 32,
        objectFit: 'contain',
        marginTop: '2px'
      }}
      alt="Logo"
      src={theme.palette.mode === 'dark' ? "/assets/logo_dark.png" : "/assets/logo.png"}
    />
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

type LogoProps = {
  sx?: object,
  disabledLink?: boolean,
};

export default Logo;
