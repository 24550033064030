import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const StyledRoot = styled('div')(({ theme }) => ({
  
}));

export default function AuthLayout() {
  return (
    <StyledRoot>
      <Box
        component="img"
        sx={{
          height: '100%',
          width: '100%',
          position: 'absolute',
          zIndex: -1
        }}
        alt="The house from the offer."
        src="/assets/images/background.png"
      />
      <Outlet />
    </StyledRoot>
  );
}
