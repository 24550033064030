import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Header from '../sections/@list/Header';
import MenuBar from '../sections/@list/MenuBar';
import ToolBar from '../sections/@list/ToolBar';
import ChartView from '../sections/@chart/ChartView';
import useResponsive from '../hooks/useResponsive';
import { useState } from 'react';

export default function ChartPage() {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');
  const [open, setOpen] = useState(false);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.palette_style.background.default,
        boxShadow: '0 0 10px 10px rgba(0, 0, 0, 0.05)',
        width: '100%',
        height: {xs: 'calc(100% - 8px)', lg: '100%'},
        overflow: 'hidden'
      }}
    >
      <Header />
      <MenuBar search="" />
      {!isDesktop && <ToolBar open={open} onOpen={setOpen} />}
      <ChartView open={open} />
    </Box>
  );
}
