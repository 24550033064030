import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import reportWebVitals from './reportWebVitals';
import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
      {/* <AuthGuard> */}
      <Provider store={store}>
        <App />
      </Provider>
      {/* </AuthGuard> */}      
    </BrowserRouter>
   </HelmetProvider>
  // </React.StrictMode>
);
reportWebVitals();
